module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PickModemRouter","short_name":"PickModemRouter","start_url":"/","background_color":"#fff","theme_color":"#5FBAA7","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ea80571577334fd0c6bdd40bbad0fb35"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://pickmodemrouter.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../packages/gatsby-theme-seomass/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteTitle":"PickModemRouter","siteDescription":"Compare the top routers!","siteUrl":"https://pickmodemrouter.com","siteSubject":"Routers","siteSubjectSlug":"routers","siteLanguage":"en","siteImage":"/banner.jpg","siteTheme":{"mainColor":"#5FBAA7","secondColor":"#5b706c","thirdColor":"#35423f"},"siteNavigation":[{"name":"Routers","slug":"/routers/","submenu":""},{"name":"Blog","slug":"/blog/"},{"name":"About","isCustomPage":true,"slug":"/about/","staticSubMenu":[{"name":"Contact us","slug":"/contact/"}]}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-windicss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
